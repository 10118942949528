// extracted by mini-css-extract-plugin
export var quoteParagraphLeft = "w_s5 d_gS d_cw d_dv";
export var quoteParagraphCenter = "w_gT d_gT d_cw d_dw";
export var quoteParagraphRight = "w_gV d_gV d_cw d_dx";
export var quoteRowLeft = "w_s6 d_bG";
export var quoteRowCenter = "w_s7 d_bD";
export var quoteRowRight = "w_s8 d_bH";
export var quoteWrapper = "w_gQ d_gQ d_w d_cD d_bz d_bP d_bD";
export var quoteContentWrapper = "w_gR d_gR";
export var quoteExceptionSmall = "w_rr p_rr";
export var quoteExceptionNormal = "w_rs p_rs";
export var quoteExceptionLarge = "w_rt p_rt";
export var quoteAuthorExceptionSmall = "w_rv p_rv";
export var quoteAuthorExceptionNormal = "w_rw p_rw";
export var quoteAuthorExceptionLarge = "w_rx p_rx";