// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "t_sC d_gv d_cs";
export var heroHeaderCenter = "t_gw d_gw d_cs d_dw";
export var heroHeaderRight = "t_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "t_sD d_gr d_cw";
export var heroParagraphCenter = "t_gs d_gs d_cw d_dw";
export var heroParagraphRight = "t_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "t_sF d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "t_sG d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "t_sH d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "t_sJ d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "t_sK d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "t_rk p_rk";
export var heroExceptionNormal = "t_rl p_rl";
export var heroExceptionLarge = "t_rm p_rm";
export var Title1Small = "t_qX p_qX p_qr p_qs";
export var Title1Normal = "t_qY p_qY p_qr p_qt";
export var Title1Large = "t_qZ p_qZ p_qr p_qv";
export var BodySmall = "t_q9 p_q9 p_qr p_qL";
export var BodyNormal = "t_rb p_rb p_qr p_qM";
export var BodyLarge = "t_rc p_rc p_qr p_qN";