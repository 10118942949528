// extracted by mini-css-extract-plugin
export var carouselContainer = "F_vj d_w d_H d_bf d_Z";
export var carouselContainerCards = "F_vk F_vj d_w d_H d_bf d_Z";
export var carouselContainerSides = "F_vl d_w d_H d_Z";
export var prevCarouselItem = "F_vm d_w d_H d_0 d_k";
export var prevCarouselItemL = "F_vn F_vm d_w d_H d_0 d_k";
export var moveInFromLeft = "F_vp";
export var prevCarouselItemR = "F_vq F_vm d_w d_H d_0 d_k";
export var moveInFromRight = "F_vr";
export var selectedCarouselItem = "F_vs d_w d_H d_Z d_bf";
export var selectedCarouselItemL = "F_vt F_vs d_w d_H d_Z d_bf";
export var selectedCarouselItemR = "F_vv F_vs d_w d_H d_Z d_bf";
export var nextCarouselItem = "F_vw d_w d_H d_0 d_k";
export var nextCarouselItemL = "F_vx F_vw d_w d_H d_0 d_k";
export var nextCarouselItemR = "F_vy F_vw d_w d_H d_0 d_k";
export var arrowContainer = "F_vz d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainer = "F_vB F_vz d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainerHidden = "F_vC F_vB F_vz d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var nextArrowContainer = "F_vD F_vz d_H d_0 d_dB d_bl d_4 d_b1";
export var carouselContainerProducts = "F_vF";
export var nextArrowContainerHidden = "F_vG F_vD F_vz d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var arrow = "F_kG d_0";
export var prevArrow = "F_vH F_kG d_0";
export var nextArrow = "F_vJ F_kG d_0";
export var carouselIndicatorContainer = "F_vK d_w d_dw d_bl d_bz d_bD d_bP d_bJ d_0";
export var btnsContainer = "F_vL d_w d_bz d_bF";
export var carouselText = "F_vM d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var carouselTextInactive = "F_vN F_vM d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var indicator = "F_vP d_b7";
export var carouselIndicator = "F_vQ F_vP d_b7";
export var carouselIndicatorSelected = "F_vR F_vP d_b7";
export var arrowsContainerTopRight = "F_vS d_0 d_bl d_bC";
export var arrowsContainerBottomLeft = "F_vT d_0 d_bl d_bC";
export var arrowsContainerSides = "F_vV d_0 d_bl d_bC";
export var smallArrowsBase = "F_vW d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainer = "F_vX F_vW d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrev = "F_vY F_vX F_vW d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerSmall = "F_vZ F_vW d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrevSmall = "F_v0 F_vZ F_vW d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var icon = "F_p9";
export var iconSmall = "F_v1";
export var multipleWrapper = "F_v2 d_bC d_bF d_bf";
export var multipleImage = "F_v3 d_bC";
export var sidesPrevContainer = "F_v4 F_vX F_vW d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";
export var sidesNextContainer = "F_v5 F_vX F_vW d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";